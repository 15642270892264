exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-expertisen-coolify-js": () => import("./../../../src/pages/expertisen/coolify.js" /* webpackChunkName: "component---src-pages-expertisen-coolify-js" */),
  "component---src-pages-expertisen-index-js": () => import("./../../../src/pages/expertisen/index.js" /* webpackChunkName: "component---src-pages-expertisen-index-js" */),
  "component---src-pages-expertisen-proxmox-js": () => import("./../../../src/pages/expertisen/proxmox.js" /* webpackChunkName: "component---src-pages-expertisen-proxmox-js" */),
  "component---src-pages-hetzner-js": () => import("./../../../src/pages/hetzner.js" /* webpackChunkName: "component---src-pages-hetzner-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-leistungen-js": () => import("./../../../src/pages/leistungen.js" /* webpackChunkName: "component---src-pages-leistungen-js" */),
  "component---src-templates-archive-template-js": () => import("./../../../src/templates/archiveTemplate.js" /* webpackChunkName: "component---src-templates-archive-template-js" */),
  "component---src-templates-post-template-js": () => import("./../../../src/templates/postTemplate.js" /* webpackChunkName: "component---src-templates-post-template-js" */)
}

